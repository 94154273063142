import React, { useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import LoginForm from "./app/public-dashboard/login/login";
import Navbar from "./app/public-dashboard/header/navbar";
import Reports from "./app/komuna-dashboard/reportspage/reports";
import UsersPage from "./app/komuna-dashboard/userspage/userspage";
import Homepage from "./app/public-dashboard/homepage/homepage";
import Footer from "./app/public-dashboard/footer/footer";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  const handleLogin = (data) => {
    setIsLoggedIn(true);
    setUserData(data);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUserData(null);
    navigate("/");
  };

  return (
    <div>
      <Navbar isLoggedIn={isLoggedIn} onLogout={handleLogout} />
      <div>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route
            path="/login"
            element={
              !isLoggedIn ? <LoginForm onLogin={handleLogin} /> : <Reports />
            }
          />
          <Route
            path="/reports"
            element={
              isLoggedIn ? <Reports /> : <LoginForm onLogin={handleLogin} />
            }
          />
          <Route
            path="/users"
            element={
              isLoggedIn ? <UsersPage /> : <LoginForm onLogin={handleLogin} />
            }
          />
          <Route
            path="/"
            element={
              isLoggedIn ? <Reports /> : <LoginForm onLogin={handleLogin} />
            }
          />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default App;
