import React, { useState, useEffect } from "react";
import axios from "axios";

const BusinessList = () => {
  const [finedBusinessesLastWeek, setFinedBusinessesLastWeek] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState(null);

  const handleBusinessClick = (index) => {
    if (selectedBusiness === index) {
      setSelectedBusiness(null);
    } else {
      setSelectedBusiness(index);
    }
  };

  useEffect(() => {
    axios
      .get("https://ndotja-akustike.info/api/fines.php")
      .then((response) => {
        setFinedBusinessesLastWeek(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <div className="bg-gradient-to-br from-white-400 to-gray-600 rounded-lg shadow-xl p-6 text-black">
      <h2 className="text-xl font-bold mb-4">Bizneset e gjobitura:</h2>
      <div className="grid grid-cols-3 gap-4 cursor-pointer">
        {Array.isArray(finedBusinessesLastWeek) && finedBusinessesLastWeek.map((business, index) => (
          <div
            key={index}
            className="text-lg"
            onClick={() => handleBusinessClick(index)}
          >
            <h3>{business.name}</h3>
            {selectedBusiness === index && (
              <div>
                <p>
                  <i>Kohezgjatja:</i> {business.duration}
                </p>
                <p>
                  <i>Decibelët: </i> {business.decibel}
                </p>
                <p>
                  <i>Data: </i> {business.date}
                </p>
                <p>
                  <i>Periudha: </i> {business.timerange}
                </p>
                <p>
                  <i>Lloji i gjobes: </i> {business.amount}
                </p>
                <p>
                  <i>Statusi i gjobes: </i> {business.status}{" "}
                </p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BusinessList;
