import React, { useEffect, useState } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.heat/dist/leaflet-heat.js";
import axios from "axios";

const Homepage = () => {
  const [map, setMap] = useState(null);
  const [topStreets, setTopStreets] = useState([]);
  const [selectedStreet, setSelectedStreet] = useState(null);
  const [selectedBusinesses, setSelectedBusinesses] = useState([]);
  const [isListOpen, setIsListOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredStreets, setFilteredStreets] = useState([]);
  const [filteredBusinesses, setFilteredBusinesses] = useState([]);
  const [fetchedData, setFetchedData] = useState([]);

  useEffect(() => {
    const mapInstance = L.map("map").setView(
      [42.66260819226293, 21.164904871586018],
      15
    );
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {}).addTo(
      mapInstance
    );
    var allHeatLayer = L.heatLayer([], {
      radius: 20,
      opacity: 1,
      gradient: {
        0: "Navy",
        0.25: "Blue",
        0.5: "Green",
        0.75: "Yellow",
        1: "Red",
      },
    }).addTo(mapInstance);

    const fetchData = async () => {
      mapInstance.removeLayer(allHeatLayer);

      try {
        const response = await axios.get(
          "https://ndotja-akustike.info/api/biz.php"
        );
        const fetchedData = response.data;

        const allData = fetchedData.map((point) => [
          point.latitude,
          point.longitude,
          3,
        ]);
        allHeatLayer = L.heatLayer(allData, {
          radius: 20,
          opacity: 1,
          gradient: {
            0: "Navy",
            0.25: "Blue",
            0.5: "Green",
            0.75: "Yellow",
            1: "Red",
          },
        }).addTo(mapInstance);

        setMap(mapInstance);

        const sortedData = fetchedData
          .sort
          //(a, b) => b.measurement - a.measurement
          ();
        setFetchedData(fetchedData);
        const allStreets = [
          ...new Set(fetchedData.map((point) => point.address)),
        ];
        setTopStreets(allStreets); // Update topStreets here
        setFilteredBusinesses(fetchedData);
        setFilteredStreets(allStreets); // Initially set filteredStreets to allStreets

        // If selectedStreet exists in the fetched data, update selectedBusinesses
        if (selectedStreet) {
          const businessesOnSelectedStreet = fetchedData.filter(
            (point) => point.address === selectedStreet
          );
          setSelectedBusinesses(businessesOnSelectedStreet);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    // Set up polling every 1 minute (60000 milliseconds)
    const intervalId = setInterval(() => {
      fetchData();
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const handleStreetClick = (street) => {
    if (selectedStreet === street) {
      setSelectedStreet(null);
      setIsListOpen(false);
    } else {
      const businessesOnStreet = filteredBusinesses.filter(
        (point) => point.address === street
      );
      setSelectedStreet(street);
      setSelectedBusinesses(businessesOnStreet);
      setIsListOpen(true);
    }
  };

  const handleClick = (business) => {
    if (map) {
      map.setView([business.latitude, business.longitude], 17);

      const marker = L.marker([business.latitude, business.longitude])
        .addTo(map)
        .bindPopup(
          `${business.name} - Niveli i zhurmes: ${business.measurement} dB`
        )
        .openPopup();
    }
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    const filteredStreets = topStreets.filter((street) =>
      street.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredStreets(filteredStreets);

    const filteredBusinesses = fetchedData.filter((business) =>
      business.address.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredBusinesses(filteredBusinesses);
  };

  return (
    <div className="w-full min-h-screen bg-gray-100 flex flex-col">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-4">
        <div className="md:col-span-1 m-2 bg-white p-4 rounded-md overflow-auto">
          <h2 className="text-lg font-bold mb-4">Lista e rrugëve</h2>
          <input
            type="text"
            placeholder="Kërko sipas emrit të rrugëve"
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value)}
            className="w-full p-2 mb-4 rounded-md border border-gray-300"
          />
          <div className="h-[320px] md:h-[520px] overflow-y-auto">
            <table className="w-full mb-4 text-sm">
              <thead>
                <tr>
                  <th className="w-1/2 py-2">Emrat e rrugëve</th>
                  {/* <th className="w-1/2 py-2">Avg dB</th> */}
                </tr>
              </thead>
              <tbody>
                {filteredStreets.map((street, index) => {
                  const businessesOnStreet = filteredBusinesses.filter(
                    (point) => point.address === street
                  );

                  return (
                    <React.Fragment key={index}>
                      <tr
                        onClick={() => handleStreetClick(street)}
                        className="cursor-pointer hover:bg-gray-200 transition duration-300"
                        style={{
                          boxShadow:
                            "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
                        }}
                      >
                        <td className="py-2 pl-4">{street}</td>
                      </tr>
                      {selectedStreet === street && isListOpen && (
                        <tr>
                          <td colSpan="2">
                            <table className="w-full bg-gray-100 text-sm">
                              <thead>
                                <tr>
                                  <th className="w-70%">Emri i Biznesit</th>
                                  <th className="w-30%">
                                    Niveli i Zhurmes (dB)
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {businessesOnStreet.map((business, index) => (
                                  <tr
                                    key={index}
                                    onClick={() => handleClick(business)}
                                    className="cursor-pointer hover:bg-gray-200 transition duration-300"
                                    style={{
                                      boxShadow:
                                        "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
                                    }}
                                  >
                                    <td className="py-2 pl-4">
                                      {business.name}
                                    </td>
                                    <td className="text-center py-2">
                                      {business.measurement}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="md:col-span-2 m-2 bg-white p-4 rounded-md">
          <h2 className="text-lg font-bold mb-4">Harta e nivelit të zhurmës</h2>
          <div
            id="map"
            className="w-full h-[320px] md:h-[520px] lg:h-[600px]"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
