import React from "react";
import BusinessBox from "../components/BusinessBox";
import FrequencyBox from "../components/FrequencyBox";
import BusinessList from "../components/BusinessList";
import FrequencyGraph from "../components/FrequencyGraph";

function Reports() {
  return (
    <div className="flex flex-col h-screen">
      <div className="flex">
        <div className="w-1/2 p-4">
          <BusinessBox />
        </div>
        <div className="w-1/2 p-4">
          <FrequencyBox />
        </div>
      </div>
      <div className="flex-1 p-4">
        <BusinessList />
      </div>
      <div className="flex-1 pt-4">
        <FrequencyGraph />
      </div>
    </div>
  );
}

export default Reports;
