// FrequencyGraph.js
import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import businessData from "./businessdata";

const FrequencyGraph = () => {
  const { frequencyGraphData } = businessData;
  const chartRef = useRef();

  useEffect(() => {
    if (chartRef && chartRef.current) {
      const ctx = chartRef.current.getContext("2d");
      new Chart(ctx, {
        type: "line",
        data: {
          labels: Array.from(
            { length: frequencyGraphData.length },
            (_, i) => `Dita ${i + 1}`
          ),
          datasets: [
            {
              label: "Frekuenca",
              data: frequencyGraphData,
              borderColor: "rgba(75, 192, 192, 1)",
              backgroundColor: "rgba(75, 192, 192, 0.2)",
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              grid: {
                color: "#CBD5E0",
              },
            },
            x: {
              grid: {
                color: "transparent",
              },
            },
          },
        },
      });
    }
  }, [frequencyGraphData]);

  return (
    <div className="bg-white rounded-lg shadow-xl p-6 text-black">
      <h2 className="text-xl font-bold mb-4">Grafi për javën e kaluar</h2>
      <div style={{ maxWidth: "100%", height: "300px", overflow: "auto" }}>
        <canvas ref={chartRef}></canvas>
      </div>
    </div>
  );
};

export default FrequencyGraph;
