import React, { useState } from "react";
import { FaSignOutAlt } from "react-icons/fa";
import axios from "axios";

const LogoutButton = ({ onLogout }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleLogout = async () => {
    try {
      setIsLoading(true);

      const response = await axios.post(
        "https://ndotja-akustike.info/api/login.php?logout=now"
      );

      if (response.data.error === "false") {
        onLogout();
        console.log("Logout successful");
      } else {
        console.error("Logout failed:", response.data.error);
      }
    } catch (error) {
      console.error("Logout failed", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <button
      onClick={handleLogout}
      disabled={isLoading}
      className="flex items-center hover:text-gray-700 ml-4"
    >
      <FaSignOutAlt size={20} className="mr-2" />
      {isLoading ? "Duke dalur..." : "Dil"}
    </button>
  );
};

export default LogoutButton;
