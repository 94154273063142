import React, { useState, useEffect } from 'react';

const FrequencyBox = () => {
  const [averageFrequency, setAverageFrequency] = useState(0); // Initialize with 0

  useEffect(() => {
    const minDB = 40;
    const maxDB = 50;

    // Generate a random number within the range for averageFrequency when component mounts
    const randomFrequency = Math.random() * (maxDB - minDB) + minDB;
    setAverageFrequency(randomFrequency.toFixed(1)); // Set the number of decimal places

    const interval = setInterval(() => {
      // Generate a new random value within the range
      const newRandomFrequency = Math.random() * (maxDB - minDB) + minDB;
      setAverageFrequency(newRandomFrequency.toFixed(1)); // Update the state with the new value

    }, 60000); // 60 seconds interval

    // Clean up the interval to prevent memory leaks
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="bg-gradient-to-br from-green-400 to-blue-500 rounded-lg shadow-xl p-6 text-white">
      <h2 className="text-xl font-bold mb-4">Niveli mesatar i zhurmës</h2>
      <p className="text-2xl">{averageFrequency} dB</p>
    </div>
  );
};

export default FrequencyBox;
