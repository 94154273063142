import React from "react";
import { Link } from "react-router-dom";
import LogoutButton from "../logout/logout";
import logo from "../../../assets/logo.png";
import { FaSignInAlt } from "react-icons/fa";

const Navbar = ({ isLoggedIn, onLogout, onLogin }) => {
  return (
    <nav className="bg-gray-50 p-4">
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-xl sm:text-2xl lg:text-2xl px-2 relative">
          <Link to="/">Ndotja Akustike</Link>
        </div>
        <div className="flex items-center relative">
          <img className="w-[40px] h-[50px]" src={logo} alt="logo1" />
          <Link
            to="/"
            className="text-xl sm:text-2xl lg:text-2xl px-2 relative"
          >
            Komuna e Prishtines
          </Link>
        </div>
        <div className="flex items-center space-x-4">
          {isLoggedIn ? (
            <LoggedInNav onLogout={onLogout} />
          ) : (
            <LoggedOutNav onLogin={onLogin} />
          )}
        </div>
      </div>
    </nav>
  );
};

const LoggedInNav = ({ onLogout }) => {
  return (
    <div className="flex items-center">
      <Link
        className="ml-4 bg-gradient-to-br from-white-400 to-gray-600  shadow-xl text-black font-semibold py-2 px-4 rounded-lg"
        to="/reports"
      >
        Raportet
      </Link>
      <Link
        to="/users"
        className="ml-4 bg-gradient-to-br from-white-400 to-gray-600  shadow-xl text-black font-semibold py-2 px-4 rounded-lg"
      >
        Subjektet
      </Link>
      <p className="flex items-center hover:text-gray-700 ml-4">
        Miresevjen, Inspektor!
      </p>
      <LogoutButton onLogout={onLogout} />
    </div>
  );
};

const LoggedOutNav = () => {
  return (
    <div>
      <Link to="/login" className="flex items-center hover:text-gray-700 ml-4">
        <FaSignInAlt size={20} className="mr-[5px]" />
        Kyqu
      </Link>
    </div>
  );
};

export default Navbar;
