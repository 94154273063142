import React from "react";

const Footer = () => {
  return (
    <footer className="text-sm p-4 text-center bg-gray-50">
      <p className="font-bold">
        Komuna e Prishtinës 2023{" "}
        <span className="font-semibold text-[12px]">
          (Kujdes: Faza testuese e ndotjes akustike)
        </span>
      </p>
    </footer>
  );
};

export default Footer;
