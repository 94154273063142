import React, { useState, useEffect } from "react";
import axios from "axios";

const BusinessBox = () => {
  const [businesses, setBusinesses] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [filteredBusinesses, setFilteredBusinesses] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState(new Date()); 
  const [endDate, setEndDate] = useState(new Date());
  const [reportData, setReportData] = useState([]);

  const today = new Date().toISOString().split('T')[0];
  const thirtyDaysAgo = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const toggleDropdown = (id) => {
    setSelectedBusiness(id);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://ndotja-akustike.info/api/list.php");
        if (Array.isArray(response.data)) {
          setBusinesses(response.data);
          setFilteredBusinesses(response.data);
        } else {
          console.error("Expected an array but received:", typeof response.data);
          setBusinesses([]);
          setFilteredBusinesses([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (Array.isArray(businesses)) {
      const filtered = businesses.filter((business) => business.name.toLowerCase().includes(searchTerm.toLowerCase()));
      setFilteredBusinesses(filtered);
    }
  }, [searchTerm, businesses]);

  const fetchReportData = async () => {
    if (!selectedBusiness || !startDate || !endDate) {
      console.error("Missing required fields");
      return;
    }

    const reportDataArray = [];
    const formattedStartDate = new Date(startDate);
    const formattedEndDate = new Date(endDate);
    formattedEndDate.setHours(23, 59, 59);

    // Iterate over each day between start and end dates
    let currentDate = new Date(formattedStartDate);
    while (currentDate <= formattedEndDate) {
      try {
        const formattedDate = currentDate.toISOString().split('T')[0] + " 00:00:00";
        const response = await axios.get(`https://ndotja-akustike.info/api/report.php`, {
          params: {
            bid: selectedBusiness,
            from: formattedDate,
            to: formattedDate.split(' ')[0] + " 23:59:59",
          },
        });
        // Push the report data for the current day to the array
        reportDataArray.push(...response.data);
      } catch (error) {
        console.error("Error fetching report data for date:", currentDate, error);
      }
      // Move to the next day
      currentDate.setDate(currentDate.getDate() + 1);
    }
    // Set the report data to the array containing data for each day
    setReportData(reportDataArray);
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    const date = new Date(dateString);
    // Format parts of the date
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // +1 because months are 0-indexed
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    
    // Combine them in the desired format
    return `${day}/${month}/${year}  ${hours}--${minutes}--${seconds}`;
  };

  return (
    <div className="bg-gradient-to-br from-white-400 to-gray-600 rounded-lg shadow-xl p-6 text-black">
      <h2 className="text-xl font-bold mb-4">Raporte per biznese</h2>
      <p className="text-2xl cursor-pointer border-b-2 border-transparent hover:text-blue-500" onClick={openModal}>
        Shiko raporte
      </p>

      {modalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-[90%] max-w-4xl overflow-auto" style={{ maxHeight: '80vh' }}>
            <h2 className="text-xl font-bold mb-4">Shiko raporte:</h2>
            <select className="mb-4 mr-2" onChange={(e) => toggleDropdown(e.target.value)} value={selectedBusiness ?? ""}>
              <option value="" disabled>Zgjedh biznesin</option>
              {filteredBusinesses.map((business) => (
                <option key={business.id} value={business.id}>{business.name}</option>
              ))}
            </select>

            <input
              type="date"
              id="startDate"
              value={startDate.toISOString().split('T')[0]}
              onChange={(e) => setStartDate(new Date(e.target.value))}
              className="mb-4 mr-2"
              min={thirtyDaysAgo} // Set minimum date to 30 days ago
              max={today} // Set maximum date to today
            />
            <input
              type="date"
              id="endDate"
              value={endDate.toISOString().split('T')[0]}
              onChange={(e) => setEndDate(new Date(e.target.value))}
              className="mb-4 mr-2"
              min={startDate.toISOString().split('T')[0]} // Ensure endDate is after startDate
              max={today} // Set maximum date to today
            />
            <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 ml-2" onClick={fetchReportData}>Shiko Raportin</button>
            {reportData.length > 0 && (
              <div className="overflow-x-auto mt-6">
                <table className="min-w-full table-auto">
                  <thead className="bg-gray-200 text-gray-600">
                    <tr>
                      <th className="px-4 py-2">Data/Koha</th>
                      <th className="px-4 py-2">Mesatarja</th>
                      <th className="px-4 py-2">Vlera maksimale</th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-700">
                    {reportData.map((item, index) => (
                      <tr key={index} className="bg-white border-b">
                      <td className="px-4 py-2 border">{formatDate(item.timestamp)}</td>
                      <td className="px-4 py-2 border">{parseFloat(item.avg).toFixed(2)}</td>
                      <td className="px-4 py-2 border">{parseFloat(item.max).toFixed(2)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <button className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700 ml-4 mt-2" onClick={closeModal}>Mbyll</button>
        </div>
      </div>
    )}
  </div>
);
};

export default BusinessBox;