import React, { useState } from "react";
import axios from "axios";

const LoginForm = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const validateForm = () => {
    if (!email || !password) {
      setError("Ju lutem mbushini të gjitha hapësirat!");
      return false;
    }
    return true;
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const response = await axios.post(
        `https://ndotja-akustike.info/api/login.php?email=${email}&password=${password}`
      );

      if (response.data.error === "false") {
        onLogin(response.data);
        console.log("Login successful");
      } else {
        setError("Emaili ose paswordi juaj është shënuar gabim!");
        console.error("Login failed");
      }
    } catch (error) {
      setError("Diçka shkoi keq. Ju lutem provoni përsëri më vonë.");
      console.error("Login failed", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen overflow-auto">
      <form
        onSubmit={handleLogin}
        className="max-w-md w-full p-6 bg-white rounded shadow-md"
      >
        <h2 className="text-2xl font-semibold mb-4 text-center">
          Mirë se vini!
        </h2>
        <p className="text-center text-gray-600 mb-4">
          Qasja vetëm për e-mail zyrtarë
        </p>
        <div className="mb-4">
          <input
            id="email"
            className="w-full px-3 py-2 border rounded focus:outline-none focus:border-blue-500"
            type="text"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <input
            id="password"
            className="w-full px-3 py-2 border rounded focus:outline-none focus:border-blue-500"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
        <button
          type="submit"
          className={`w-full bg-blue-500 text-white p-3 rounded focus:outline-none ${
            loading ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-600"
          }`}
          disabled={loading}
        >
          {loading ? "Duke u kyqur..." : "Kyqu"}
        </button>
      </form>
    </div>
  );
};

export default LoginForm;
