import React, { useEffect, useState } from "react";
import axios from "axios";
import { Line } from "react-chartjs-2";

const UsersPage = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [businesses, setBusinesses] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://ndotja-akustike.info/api/list.php"
        );
        const result = response.data;
        setBusinesses(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const applySearch = () => {
    if (!searchTerm) {
      return businesses;
    } else {
      return businesses.filter((business) => {
        const searchTermLower = searchTerm.toLowerCase();
        return (
          business.name.toLowerCase().includes(searchTermLower) ||
          business.address.toLowerCase().includes(searchTermLower) ||
          business.phone.toLowerCase().includes(searchTermLower) ||
          business.email.toLowerCase().includes(searchTermLower) ||
          business.business_id.toLowerCase().includes(searchTermLower)
        );
      });
    }
  };

  const handleSearchClick = () => {
    setBusinesses(applySearch());
  };

  const [newBusiness, setNewBusiness] = useState({
    name: "",
    address: "",
    phone: "",
    email: "",
    business_id: "",
  });
  const [selectedBusiness, setSelectedBusiness] = useState(null);

  const openModal = (index) => {
    setSelectedBusiness(index);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedBusiness(null);
  };

  return (
    <div className="mb-6">
      <div className="p-4">
        <div className="flex justify-between mb-4">
          <div className="flex justify-between">
            <input
              type="text"
              placeholder="Search..."
              onChange={handleSearch}
              className="w-5/6 p-2 border border-gray-300 rounded-md mr-2"
            />
            <button
              onClick={handleSearchClick}
              className="bg-blue-500 text-white px-4 py-2 rounded-md"
            >
              Search
            </button>
          </div>
        </div>
        <div className="mb-4">
          <table className="min-w-full divide-y divide-gray-200 shadow-md rounded-md overflow-hidden">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Emri
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Adresa
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Numri i telefonit
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Email
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  ID e biznesit
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {Array.isArray(businesses) ? (
                businesses.map((business, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <button
                        onClick={() => openModal(index)}
                        className="text-blue-500 cursor-pointer"
                      >
                        {business.name}
                      </button>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {business.address}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {business.phone}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {business.email}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {business.business_id}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="5"
                    className="px-6 py-4 whitespace-nowrap text-center"
                  >
                    Loading or no data available.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {modalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-8 rounded-lg w-3/4">
            <h2 className="text-xl font-bold mb-4 text-center">
              {businesses[selectedBusiness]?.name}
            </h2>
            <div className="flex justify-between mb-4">
              <p className="flex items-center">
                <span className="font-bold">ID e biznesit:</span>{" "}
                {businesses[selectedBusiness]?.business_id}
              </p>
              <p className="flex items-center">
                <span className="font-bold">Email:</span>{" "}
                {businesses[selectedBusiness]?.email}
              </p>
              <p className="flex items-center">
                <span className="font-bold">Numri i telefonit:</span>{" "}
                {businesses[selectedBusiness]?.phone}
              </p>
            </div>

            {/* {/* Box displaying current dB and average dB */}
            <div className="flex justify-between mb-4">
              <div className="bg-gradient-to-br from-white-400 to-gray-600 rounded-lg shadow-xl p-6 text-black w-1/2">
                <h3 className="text-lg font-bold mb-2">Momentalisht: </h3>
                <p className="text-gray-600">
                  {businesses[selectedBusiness]?.measurement} dB
                </p>
              </div>
              <div className="bg-gradient-to-br from-white-400 to-gray-600 rounded-lg shadow-xl p-6 text-black w-1/2">
                <h3 className="text-lg font-bold mb-2">
                  Mesatarja ne 24 orët e fundit:
                </h3>
                <p className="text-gray-600">
                  {businesses[selectedBusiness]?.average} dB
                </p>
              </div>
            </div>
            <div className="mt-6">
              <h3 className="text-lg font-bold mb-2">
                Grafiku per javen e kaluar:
              </h3>
              <div className="bg-gray-200 p-4 rounded-md">
                {/* Chart.js Line chart */}
                <Line
                  data={{
                    labels: [
                      "E Hene",
                      "E Marte",
                      "E Merkure",
                      "E Enjte",
                      "E Premte",
                      "E Shtune",
                      "E Diell",
                    ],
                    datasets: [
                      {
                        label: "Frekuenca",
                        data: businesses[selectedBusiness]?.measurements.map(
                          (measurement) => measurement.dB
                        ),
                        backgroundColor: "rgba(109, 215, 185, 0.5)",
                        borderColor: "#6DD7B9",
                        borderWidth: 2,
                        pointRadius: 5,
                        pointBackgroundColor: "#6DD7B9",
                      },
                    ],
                  }}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      y: {
                        beginAtZero: true,
                        title: {
                          display: true,
                          text: "Frekuenca",
                        },
                      },
                      x: {
                        title: {
                          display: true,
                          text: "Ditet",
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>

            <button
              onClick={closeModal}
              className="bg-blue-500 text-white px-4 py-2 rounded-md mt-6"
            >
              Mbylle
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UsersPage;
